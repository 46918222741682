export const ENV_VARS = {
  MAINTENANCE_ENABLED: "MAINTENANCE_ENABLED",

  SIGN_UP_API_URL: "SIGN_UP_API_URL",
  ACCOUNT_REDIRECT_URL: "ACCOUNT_REDIRECT_URL",
  ACCOUNT_FORGOTTEN_PASSWORD_URL: "ACCOUNT_FORGOTTEN_PASSWORD_URL",
  SIGN_IN_URL: "SIGN_IN_URL"
};

export const getEnv = (variableName) => {
  const varKey = "REACT_APP_" + variableName;

  const getFromWindow = (key) => (window && window._env_ ? window._env_[key] : null);

  // Do not rewrite like process && process.env because it will lead to 'Process is not defined error'
  const getFromProcess = (key) => (process.env.NODE_ENV ? process.env[key] : null);

  return getFromWindow(varKey) ?? getFromProcess(varKey);
};

export default ENV_VARS;
